<template>
  <div>
    <div class="textbook">
      <div class="textbookCover">
        <div class="imageWrap">
          <el-image :src="bookDetails.faceImg" class="image"></el-image>
        </div>
        <div class="tipsWrap">
          <div class="title">{{ bookDetails.textbookName }}</div>
          <div class="tips">作者：{{ bookDetails.teacherName }}</div>
          <div class="tips">
            出版时间：{{ bookDetails.researchEndtime.substring(10, 0) }}
          </div>
          <div class="tips">字数：{{ bookDetails.totalChar }}</div>
          <div class="tips">
            <span>所属套餐：</span>
            <span class="text-FF5E51">{{ title }}</span>
          </div>
          <div class="btnWrap">
            <el-button type="danger" @click="zhifu" v-if="!IsPurchase"
              >购买套餐</el-button
            >
            <el-button @click="seeTextbook" v-if="!IsPurchase"
              >试看教材</el-button
            >
            <el-button @click="seeTextbook" v-else>阅读教材</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contentWrap">
        <div class="tabsWrap">
          <div
            v-for="item in tabsList"
            :key="item.index"
            :class="{ tabsItem: true, selected: item.index == index }"
            @click="tabsChange(item.index)"
          >
            <div>{{ item.name }}</div>
            <div class="on" v-show="item.index == index"></div>
          </div>
        </div>
        <!-- 教材简介 -->
        <div v-show="index == 1" class="introduction">
          <div v-html="bookDetails.textBookNote"></div>
          <div class="nothing" v-if="!bookDetails.textBookNote">
            <div class="nothing-text">暂无内容哦~</div>
          </div>
        </div>
        <!-- 目录 -->
        <div v-show="index == 2">
          <div v-html="bookDetails.chapterText"></div>
          <div class="nothing" v-if="!bookDetails.chapterText">
            <div class="nothing-text">暂无内容哦~</div>
          </div>
        </div>
      </div>
      <you-love :id="classesId" class="youlove" :ifteacher="false" :type="1"
        >推荐套餐</you-love
      >
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus";
import Vue from "vue";
import YouLove from "@/components/Know/youLove.vue";
import { getCurSelfResearchTextbook } from "@/api/home.js";
import { getInfo, getToken } from "@/api/cookies";
export default {
  components: {
    YouLove,
  },
  data() {
    return {
      index: 1,
      tabsList: [
        { name: "教材简介", index: 1 },
        { name: "目录", index: 2 },
      ],
      id: null,
      title: null,
      bookDetails: {},
      classesId: null, // 套餐id
      IsPurchase: null,
    };
  },
  async created() {
    this.id = this.$route.query.id;
    this.classesId = this.$route.query.classesId;
    this.title = this.$route.query.title;
    this.IsPurchase = eval(this.$route.query.IsPurchase);
    this.getCurSelfResearchTextbook();
  },
  methods: {
    // 点击立即报名按钮 跳转支付页
    zhifu() {
      let token = getToken();
      if (token) {
        this.$router.push(`/orders/classOrder?classId=${this.classesId}`);
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
    /* 教材详情 */
    getCurSelfResearchTextbook() {
      getCurSelfResearchTextbook(this.id).then((res) => {
        this.bookDetails = res.msg;
      });
    },
    /* 点击阅读/试看教材 */
    seeTextbook() {
      this.$router.push({
        path: "/VIP/textbookPage",
        query: {
          IsPurchase: this.IsPurchase,
          id: this.id,
          classesId: this.classesId,
          title: this.title,
        },
      });
    },
    tabsChange(index) {
      this.index = index;
    },
  },
};
</script>

<style lang="less" scoped>
.textbook {
  width: 100%;
  height: 344px;
  background-color: #ffffff;
  margin-bottom: 20px;
  .textbookCover {
    width: 1220px;
    height: 344px;
    margin: auto;
    padding-top: 32px;
    display: flex;
    .imageWrap {
      width: 220px;
      height: 280px;
      border: solid 1px #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        width: 180px;
        height: 240px;
        border-radius: 4px;
      }
    }
    .tipsWrap {
      margin-left: 20px;
      .title {
        color: #333333;
        font-size: 18px;
        margin-bottom: 28px;
      }
      .tips {
        color: #666666;
        font-size: 14px;
        margin-bottom: 19px;
        .text-FF5E51 {
          color: #ff5e51;
        }
      }
      .btnWrap {
        margin-top: 50px;
      }
    }
  }
}
.content {
  display: flex;
  width: 1220px;
  margin: auto;
  .contentWrap {
    width: 880px;
    padding: 24px 32px;
    background: #ffffff;
    min-height: 609px;
    margin-right: 20px;
    .tabsWrap {
      height: 40px;
      display: flex;
      border-bottom: solid 1px #eeeeee;
      .tabsItem {
        margin-right: 40px;
        color: #666666;
        cursor: pointer;
      }
      .selected {
        height: 20px;
        color: #333333;
        font-weight: bold;
      }
      .on {
        width: 32px;
        height: 2px;
        background: #ff5e51;
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        margin: 10px auto auto auto;
      }
    }
  }
  .introduction {
    line-height: 32px;
    font-family: SC-Regular;
  }
  .youlove {
    width: 320px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
  }
}
</style>