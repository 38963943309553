import { render, staticRenderFns } from "./textbookDetails.vue?vue&type=template&id=0ae13114&scoped=true&"
import script from "./textbookDetails.vue?vue&type=script&lang=js&"
export * from "./textbookDetails.vue?vue&type=script&lang=js&"
import style0 from "./textbookDetails.vue?vue&type=style&index=0&id=0ae13114&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae13114",
  null
  
)

export default component.exports